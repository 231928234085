body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.FlashMessages .Toastify__toast {
  border-radius: 4px;
  font-size: 0.8em;
  min-height: 50px;
}
.FlashMessages .Toastify__toast--success {
  background: var(--success);
}
.FlashMessages .Toastify__toast--error {
  background: var(--danger);
}
.FlashMessages .Toastify__toast-body {
  padding: 0 15px;
  text-align: left;
}

@media (max-width: 700px) {
  #drift-widget-container, .drift-frame-chat {
    display: none !important;
  }
}
